import {http, param} from '../../utils/http'
import conf from '../../config'
export const API_ROOT = `${conf.API_URL}`


const sitesService = {

    findSites(payload) {
        var arr = [
            { query: 'pageNumber', val: payload.pageNumber },
            { query: 'pageSize', val: payload.pageSize },
            { query: 'sortField', val: payload.sortField },
            { query: 'sortDirection', val: payload.sortDirection },
            { query: 'search', val: payload.search ? encodeURIComponent(payload.search.trim() ): '' },
            { query: 'status', val: payload.status },
            { query: 'organizationId', val: payload.organizationId },
            { query: 'siteName', val: payload.siteName },
        ]
        return http.get(`${API_ROOT}/provider/sites${param(arr)}`).then(resp => {
            return resp
        })
    },

    createSite(payload){
        return http.post(`${API_ROOT}/provider/sites/createSite`, payload).then(resp => {
            return resp
        })
    },
    getUserSiteforRecallMeta(recallId)
    {
        return http.get(`${API_ROOT}/provider/sites/usersitesforrecall/${recallId}`).then(resp => {
            return resp
        })
    },
    getSitebyId(id){
        return http.get(`${API_ROOT}/provider/sites/${id}`).then(resp => {
            return resp
        })
    },
    getUserSitesById(){
        return http.get(`${API_ROOT}/provider/sites/usersites`).then(resp => {
            return resp
        })
    },
    getUserListBySiteId(sid){
        return http.get(`${API_ROOT}/provider/sites/sitesinfo/${sid}`).then(resp => {
            return resp
        })
    },
    getAllUserForSiteMetas(){
        return http.get(`${API_ROOT}/provider/sites/alluserforsites`).then(resp => {
            return resp
        })
    },

    activeDeactiveSite(site_id, Status) {
        return http.put(`${API_ROOT}/provider/sites/delete/${site_id}/${Status}`).then(resp => {
            return resp
        })
    },
    DeactiveSiteByReAssignSiteId(site_id, Status)
    {
        return http.put(`${API_ROOT}/provider/sites/deactivatesite/${site_id}/${Status}`).then(resp => {
            return resp
        })
    }
}

export default sitesService