import sitesService from '../../../../services/provider/sites'

export default {
    data() {
        return {
            item: {
                siteName: null,
                site_id: 0,
                add1: null,
                add2: null,
                city: null,
                state: null,
                zip: null,
                user_id: [],
                gln: null
            },
            formType: 'INSERT',
            roleMetas: [],
            organizations: [],
            labelName: {
                siteName: 'Site Name *',
            },
            siteList: [],
            UsersMetas: [],
        }
    },

    computed: {
        form() {
            if(this.formType == 'INSERT'){
                return {
                    title: 'New Site',
                    isDisable: false
                }
            }
            if(this.formType == 'UPDATE'){
                return {
                    title: 'Update Site',
                    isDisable: true
                }
            }
            if(this.formType == 'VIEW'){
                return {
                    title: 'View Site Details',
                    isDisabled: true
                }
            }
        }
    },

    methods: {
        forceRerenderInsert() {
            this.formType = 'INSERT'
            this.$refs.modal.show()
            this.getAllUserForSiteMetas()
        },
        forceRerenderUpdate(site_id) {
            this.formType = 'UPDATE'
            this.item.site_id = site_id
            this.getSiteByID(site_id)
            this.getAllUserForSiteMetas()
        },
        forceRerenderView(id, siteName) {
            this.formType = 'VIEW'
            this.item.siteName = siteName
            this.getUserListBySiteId(id)
        },
        async save(){
            var isValid = await this.$refs['observer'].validate()
            if(isValid && this.formType == 'INSERT'){
                this.createSite()
            }
            if(isValid && this.formType == 'UPDATE'){
                this.createSite()
            }
        },
        createSite() {
            sitesService.createSite(this.item).then(resp =>{
                if (!resp.error){
                    if (this.formType == 'INSERT') {
                        this.$emit('onCompleted', 'INSERT')
                        this.$refs.modal.hide()
                    }
                    if (this.formType == 'UPDATE') {
                        this.$emit('onCompleted', 'UPDATE')
                        this.$refs.modal.hide()
                    }
                }
            })
        },
        getSiteByID(siteId){
            sitesService.getSitebyId(siteId).then(resp => {
                if(!resp.error){
                    this.item=resp.data.d
                    this.$nextTick(()=>{
                        this.$refs.modal.show()
                    })
                }
            })
        },

        getUserListBySiteId(siteId){
            sitesService.getUserListBySiteId(siteId).then(resp => {
                if(!resp.error){
                    this.siteList = resp.data.d.filter(s=>s.status!='PENDING')
                    this.$nextTick(()=>{
                        this.$refs.modal.show()
                    })
                }
            })
        },
        getAllUserForSiteMetas(){
            sitesService.getAllUserForSiteMetas().then(resp => {
                if(!resp.error){
                    this.UsersMetas = resp.data.d
                    this.$nextTick(()=>{
                        this.$refs.modal.show()
                    })
                }
            })
        }

    }
}